import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
// import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import PropTypes from "prop-types";
import { DialogContainer } from "react-md";
import { setImageQuality, IMAGES_BASE_URL } from "../../settings";
import { commafy } from "../../helper/Commafy";
import { getvaluesfromURL } from "../../helper/Filter";
import { SearchURLGenerator } from "../../helper/Result";
import personWhite from "../../assets/images/common/person-white.svg";
import locationWhite from "../../assets/images/common/location-white.svg";
import arrow from "../../assets/images/common/black-arrow.svg";
import {  DEDICATED_DESK_CAPITALIZED,  HOT_DESK_CAPITALIZED,  LOCATION_WITH_MULTIPLE_SPACES,  LOCATION_WITH_SINGLE_SPACE,  MINUS, PLUS } from "./constants";
import { UPDATE_MOUSE_OVER } from '../../redux/actionTypes';
import { getAllSpaces } from '../Result/ResultAction';

const NearestSpacesMap = ({ mapSize, center, markerColor, markerData }) => {
  const dispatch = useDispatch();
  const spaces = useSelector((state) => state?.searchResult?.allSpaces?.data || []);
  const [visible, setVisible] = useState(false);
  const searchURLString = useSelector((state) => state?.router?.location?.search || "");
  const activities = useSelector((state) => state?.searchResult?.spaceActivities?.data || []);
  const locationMeta = useSelector((state) => state?.locationMeta?.coordinates || {});
  const truncate = (spaceNameAndEvent) => (typeof spaceNameAndEvent == "string" && spaceNameAndEvent.length > 27 ? `${spaceNameAndEvent.substring(0, 27)}...` : spaceNameAndEvent);
  const mapCenter = markerData.lat && markerData.lng ? { lat: markerData.lat, lng: markerData.lng } : center;
  const [currentShowingSpaceIndex, setCurrentShowingSpaceIndex] = useState(0);
  const [processedSpaceData, setProcessedSpaceData] = useState([]);
  const [selectedSpaceDetails, setSelectedSpaceDetails] = useState([]);
  const [displayingPopupDetails, setDisplayingPopUpDetails] = useState([]);
  const {country} = useParams();
  const markerPin = require("../../assets/images/common/pin.svg");

  useEffect(() => {
    if (Boolean(spaces.length)) {
      const tempProcessedDataArray = [];
      const tempCheckedIds = [];
      spaces.forEach((space) => {
        if (!tempCheckedIds.includes(space.id)) {
          const similarityCheck = spaces?.filter((spaceIndex) => spaceIndex?.location?.x === space?.location?.x && spaceIndex?.location?.y === space?.location?.y);
          similarityCheck.forEach((checkedSpace) => {
            tempCheckedIds.push(checkedSpace?.id);
          })
          if (similarityCheck.length === 1) {
            tempProcessedDataArray.push({ type: LOCATION_WITH_SINGLE_SPACE, data: similarityCheck });
          } else {
            tempProcessedDataArray.push({ type: LOCATION_WITH_MULTIPLE_SPACES, data: similarityCheck });
          }
        }
      });
      setProcessedSpaceData(tempProcessedDataArray);
    }
  }, [spaces]);

  useEffect(() => {
    if (selectedSpaceDetails?.data?.length > 0) {
      setDisplayingPopUpDetails(selectedSpaceDetails?.data?.[currentShowingSpaceIndex]);
    }
  }, [currentShowingSpaceIndex]);

  const viewSpaceDialog = (spaceData) => {
    dispatch({
      type: UPDATE_MOUSE_OVER,
      payload: {
        infoWondow: { lng: spaceData?.data?.[0]?.location?.x, lat: spaceData?.data?.[0]?.location?.y, currencyType: "-", price: "-" },
        colorChange: true,
      },
    });
    setCurrentShowingSpaceIndex(0);
    setSelectedSpaceDetails(spaceData);
    setDisplayingPopUpDetails(spaceData?.data?.[0]);
    setVisible(true);
  };

  const getActivityType = (eventType) => {
    for(let x=0; x<activities?.length; x++){
      const activity =  activities[x]?.venueType?.filter(venueType => venueType?.code === eventType);
      if(activity.length > 0 ){
        return activity;
      }
    }
  }
  const handleSpaceClick = (id, rateUnit, eventTypeCode) => {
    setVisible(false);
    const activityType = getActivityType(eventTypeCode)?.[0];
    const getStringValues = {
      spaceId: id,
      eventTypeId: activityType?.id,
    };
    window.open(`/${country}/space/bookings?${SearchURLGenerator(getStringValues)}&unit=${rateUnit}`);
  };

  const handlePopUpSpace = (id,type) => {
    if (id !== 0 && type === MINUS) {
      setCurrentShowingSpaceIndex(id - 1);
    } else if (id < selectedSpaceDetails?.data?.length - 1 && type === PLUS){
      setCurrentShowingSpaceIndex(id + 1);
    }
  };

  return (
      <>
        <GoogleMap zoom={mapSize} center={mapCenter} defaultOptions={{ fullscreenControl: false, streetViewControl: false, mapTypeControl: false, clickableIcons: false }}>
          {/* <Marker position={{ lat: locationMeta?.lat, lng: locationMeta?.lon }} icon={{ url: markerPin }} /> */}
          <Fragment>
            {processedSpaceData?.map((mark) => (
                <>
                  {mark.type === LOCATION_WITH_SINGLE_SPACE && (
                      <div className="price-info" onClick={() => viewSpaceDialog(mark)}>
                        {/* <MarkerWithLabel
                            position={{ lat: mark?.data?.[0]?.location?.y, lng: mark?.data?.[0]?.location?.x }}
                            onClick={() => viewSpaceDialog(mark)}
                            onMouseOver={() => viewSpaceDialog(mark)}
                            labelAnchor={new window.google.maps.Point(0, 0)}
                            opacity={0}
                            labelClass={`map-price-marker
                      ${markerColor && markerData.lat === mark?.data?.[0]?.location?.y && markerData.lng === mark?.data?.[0]?.location?.x ? "map-price-marker-hover" : ""}`}
                        >
                          <div>{`${mark?.data?.[0]?.rateCurrency} ${commafy(Math.round(mark?.data?.[0]?.rate))}`}</div>
                        </MarkerWithLabel> */}
                      </div>
                  )}

                  {mark.type === LOCATION_WITH_MULTIPLE_SPACES && (
                      <div className="price-info" onClick={() => viewSpaceDialog(mark)}>
                        {/* <MarkerWithLabel
                            position={{ lat: mark?.data?.[0]?.location?.y, lng: mark?.data?.[0]?.location?.x }}
                            onClick={() => viewSpaceDialog(mark)}
                            onMouseOver={() => viewSpaceDialog(mark)}
                            labelAnchor={new window.google.maps.Point(0, 0)}
                            opacity={0}
                            labelClass={`map-price-marker
                      ${markerColor && markerData?.lat === mark?.data?.[0]?.location?.y && markerData?.lng === mark?.data?.[0]?.location?.x ? "map-price-marker-hover" : ""}`}
                        >
                          <div>{`${mark?.data?.length}`} Spaces</div>
                        </MarkerWithLabel> */}
                      </div>
                  )}
                </>
            ))}
          </Fragment>

          <div className="space-card-map">
            <DialogContainer className="space-result-modal" visible={visible} onHide={() => setVisible(false)} onMouseLeave={() => setVisible(false)} focusOnMount={false}  disableScrollLocking={true} id={'mapOverPopUpWithSpaceDetails'}>
              <div onClick={() => handleSpaceClick(displayingPopupDetails?.id, displayingPopupDetails?.rateUnit, displayingPopupDetails?.eventTypeCode)}>
                <div className="space-result-thumbnail" style={{ backgroundImage: `url(${IMAGES_BASE_URL + setImageQuality() + displayingPopupDetails?.image})` }}>
                  <div className="number-of-people">
                    <img src={personWhite} className="img" alt="person" />
                    <span className="people">{displayingPopupDetails?.capacity}</span>
                  </div>
                  {displayingPopupDetails?.location?.distance < 999 && (
                      <div className="distance-wrap">
                        <img src={locationWhite} className="img" alt="person" />
                        <div className="distance">
                          <b>{Math.round(displayingPopupDetails?.location?.distance)}</b> km
                        </div>
                      </div>
                  )}
                </div>
                <div className="space-thumbnail-content">
                  <span className="space-content-title">{truncate(displayingPopupDetails?.tags?.map((tag, index) => `${tag?.toString()} ${displayingPopupDetails?.tags?.length - 1 !== index ? "•" : ""} `).join(""))}</span>
                  <span className="content-sub-title">{displayingPopupDetails?.name}</span>
                  <div className="price-wrap">
                    <span className="price">{`${displayingPopupDetails?.rateCurrency} ${commafy(Math.round(displayingPopupDetails?.rate))}`}</span>
                    <span className="price-description">{`/${displayingPopupDetails?.eventType === DEDICATED_DESK_CAPITALIZED || displayingPopupDetails?.eventType === HOT_DESK_CAPITALIZED ? "seat/" : ""}${displayingPopupDetails?.rateUnit}`}</span>
                  </div>
                </div>
              </div>
              {selectedSpaceDetails?.data?.length > 1 && (
                  <div className="multiple-spaces-wrap">
                    <div onClick={() => handlePopUpSpace(currentShowingSpaceIndex, MINUS)}>
                      <span>
                          <img className="arrow-icon left" alt="img" src={arrow} />
                      </span>
                    </div>
                    <span className="space-count">{`${currentShowingSpaceIndex + 1} of ${selectedSpaceDetails?.data?.length}  spaces`}</span>
                    <div onClick={() => handlePopUpSpace(currentShowingSpaceIndex, PLUS)}>
                      <span>
                          <img className="arrow-icon" alt="img" src={arrow} />
                      </span>
                    </div>
                  </div>
              )}
            </DialogContainer>
          </div>
        </GoogleMap>
      </>
  );
};

NearestSpacesMap.prototype = {
  activityType: PropTypes.object,
};

export default withScriptjs(withGoogleMap(NearestSpacesMap));
